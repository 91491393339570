
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar-brand {
  font-family: "Permanent Marker", "Raleway", sans-serif;
}